<template>
  <div class="animated fadeIn">
    <div id="snackbar"></div>
    <b-row>
      <b-col sm="12">
        <b-card>
          <b-row>
            <b-col sm="12" id="preview" class="mb-3"></b-col>
          </b-row>
          <div slot="header">
            <strong>{{ staticNames.name }}&nbsp;Event Banner</strong>
            <small>Form</small>
          </div>
          <b-row>
            <b-col sm="4">
              <b-form-group>
                <label for="Title">Select Country</label>
                <b-form-select
                  v-model="country_id"
                  id="em_name"
                  :plain="true"
                  text-field="CountryName"
                  value-field="CountryID"
                  :options="country_options"
                >
                  <template slot="first">
                    <option :value="null">Select Country</option>
                  </template>
                </b-form-select>
              </b-form-group>
            </b-col>
            <b-col sm="4">
              <b-form-group>
                <label for="Title">Event Name</label>
                <b-form-select
                  class="form-control"
                  label="booking_type"
                  text-field="e_name"
                  value-field="event_id"
                  v-model="event_name"
                  :options="event_name_options"
                  placeholder=""
                >
                  <template slot="first">
                    <option :value="''" disabled>Select Event</option>
                  </template>
                </b-form-select>
              </b-form-group>
            </b-col>

            <!-- <b-col sm="4">
                <b-form-group>
                  <label for="Title">Event Id</label>
                  <b-form-input
                    type="number"
                    required
                    v-model="staticBanner.event_id"
                    id="Event_Id"
                    placeholder="Enter Event Id"
                  ></b-form-input>
                </b-form-group>
              </b-col>         -->
            <!-- <b-col sm="4">
                <b-form-group>
                  <label for="Title">Event Title</label>
                  <b-form-input
                    type="text"
                    required
                    v-model="staticBanner.eh_title"
                    id="Event_Title"
                    placeholder="Enter Event Title"
                  ></b-form-input>
                </b-form-group>
              </b-col>         -->
            <!-- <b-col sm="3">
                <b-form-group>
                  <label for="logo">
                    Banner Image File
                  </label>
                  <b-form-file
                     @change="handleFileUpload( $event )"
                    accept="image/jpeg, image/png, image/gif"
                    :plain="true"
                  ></b-form-file>
                  <input type="hidden" v-model="staticBanner.eh_banner" />
                </b-form-group>
              </b-col>  -->
          </b-row>
          <!-- <b-row>
             <b-col sm="4">
                <b-form-group>
                  <label for="Title">Url</label>
                  <b-form-input
                    type="text"
                    required
                    v-model="staticBanner.eh_url"
                    id="Url"
                    placeholder="Enter Url"
                  ></b-form-input>
                </b-form-group>
              </b-col>  
              </b-row> -->

          <b-row>
            <b-col sm="4">
              <b-form-group>
                <label for="logo"> Event Banner Image </label>
                <b-form-file
                  @change="handleFileUpload('e_banners', $event)"
                  accept="image/jpeg, image/png, image/gif"
                  :plain="true"
                ></b-form-file>
                <input type="hidden" />
                <p class="mt-2">Upload Size : 1920 x 575</p>
              </b-form-group>
              <b-img
                v-if="event_banner_path"
                width="350px"
                class="mb-3"
                thumbnail
                fluid
                :src="
                  'https://adminapi-wanastime.wanasatime.com/uploads/' +
                  event_banner_path
                "
                alt="Image 1"
              ></b-img>
            </b-col>
          </b-row>
          <b-row>
            <b-col sm="4" class="mb-3">
              <b-form-checkbox
                v-model="staticBanner.status"
                name="check-button"
                switch
              >
                Status
              </b-form-checkbox>
            </b-col>
            <!-- <b-col sm="4" v-if="banner_image">
              <b-img
                height="150px"
                width="350px"
                thumbnail
                fluid
                :src="
                  'https://adminapi-wanastime.wanasatime.com/uploads/' + banner_image
                "
                alt="Image 1"
              ></b-img>
            </b-col> -->
          </b-row>

          <b-row>
            <b-col sm="3">
              <b-button
                :disabled="isLoader"
                @click="SaveEvent()"
                size="sm"
                variant="primary"
              >
                <i class="fa fa-dot-circle-o"></i>
                {{ staticNames.name }}
              </b-button>
            </b-col>
            <b-col sm="3">
              <b-button
                :disabled="isLoader"
                v-if="staticNames.name == 'Add'"
                @click="resetEventBanner"
                size="sm"
                variant="success"
              >
                <i class="fa fa-dot-circle-o"></i> Reset
              </b-button>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import MasterService from "@/services/MasterService";
import { Switch as cSwitch } from "@coreui/vue";
import UploadService from "@/services/UploadService";

export default {
  data() {
    return {
      isLoader: false,
      country_id: null,
      country_options: [],
      event_name: "",
      event_banner_path: "",
      event_name_options: [],
      banner_image: "",
      staticBanner: {
        event_id: 1,
        eh_title: "",
        eh_banner: "",
        eh_url: "",
        status: 2,
      },
      staticNames: {
        name: "Add",
      },
      moviesList: [],
      countryList: [],
      eventList: [],
    };
  },
  components: {
    cSwitch,
  },
  computed: {
    checkBannerId() {
      if (!this.$route.query.bannerId) {
        this.country_id = null;
        this.event_name = "";
        this.staticBanner.status = "2";
        this.banner_image = "";
        this.event_banner_path = "";
        this.staticNames.name = "Add";
      }
    },
  },
  watch: {
    checkBannerId(newvalue, oldvalue) {
      alert(this.checkBannerId);
    },
  },
  beforeMount() {
    this.getAllEventsDropdown();
    MasterService.getCountryEvent()
      .then((response) => {
        console.log(response.data.List, "countryyyyy");
        response.data.List.map((item) => {
          item.value = item.CountryID;
          item.text = item.CountryName;
          if (item.CountryName === "UAE") {
            item.disabled = true;
          }
          this.country_options.push(item);
        });
      })
      .catch((error) => {
        console.log("Catch on Getting Country Event Error: ", error);
      });

    // this.getEventList();
  },
  mounted: function () {
    if (this.$route.query.bannerId) {
      console.log(this.$route.query.bannerId);

      MasterService.getEventBannerId({
        id: this.$route.query.bannerId,
      })
        .then((response) => {
          let data = response.data.data;
          console.log(response, "dataaa");
          // this.staticBanner.eh_title = data.eh_title
          // this.staticBanner.eh_banner = data.eh_banner
          // this.staticBanner.eh_url = data.eh_url
          // this.staticBanner.status = data.status
          this.country_id = data.country_id;
          this.event_name = data.event_id;
          this.staticBanner.status = this.ReturnValue(data.status);
          this.banner_image = data.eh_banner;
          this.event_banner_path = data.eh_banner;
          this.staticNames.name = "Update";
        })
        .catch((error) => {
          console.log("Catch on Getting Event Banner Error: ", error);
        });
    }
  },
  methods: {
    ReturnTrueFalse(BooleanValue) {
      if (BooleanValue == true) {
        return 1;
      } else {
        return 2;
      }
    },

    ReturnValue(value) {
      if (value == 1) {
        return true;
      } else {
        return false;
      }
    },

    getAllEventsDropdown() {
      MasterService.getAllPageEvent().then((response) => {
        console.log("responseevnts", response.data);
        if (response.error) {
          this.showMessageSnackbar(response.error);
        } else {
          this.event_name_options = response.data.data;
          console.log("eventList", this.event_name_options);
        }
      });
    },

    getEventManagerDetails: function (event_banner_id) {
      let payload = {
        event_banner_id,
      };

      MasterService.getEventBanner(payload)
        .then((response) => {
          const { data } = response;
          console.log("response", response);
          this.country_id = data.data.country_id;
          this.event_name = data.data.event_id;
          this.staticBanner.status = this.ReturnValue(data.data.status);
        })
        .catch((error) => {
          console.log("Catch on Banner-getBannerByID Error: ", error);
          this.showMessageSnackbar(
            "Oops! Something went wrong. kindly contact your administrator"
          );
        });
    },

    SaveEvent() {
      let status = this.ReturnTrueFalse(this.staticBanner.status);

      let payload = {
        country_id: this.country_id != null ? this.country_id.toString() : null,
        event_id: this.event_name,
        status: status,
        eh_banner: this.event_banner_path ? this.event_banner_path : "",
      };

      let event_banner_id = this.$route.query.bannerId
        ? this.$route.query.bannerId
        : null;

      if (event_banner_id != null) {
        MasterService.updateEventBanner(payload, event_banner_id)
          .then((response) => {
            const { data } = response;
            this.showMessageSnackbar(
              data.message
                ? data.message
                : data.error
                ? data.error.message
                  ? data.error.message
                  : data.error
                : data
            );
            if (data.message) {
              setTimeout(() => {
                this.$router.push("/events/eventbannerlist");
              }, 3000);
            }
          })
          .catch((error) => {
            console.log("Catch on Banner-getBannerByID Error: ", error);
            this.showMessageSnackbar(
              "Oops! Something went wrong. Kindly contact your administrator"
            );
          });
      } else {
        MasterService.addEventBanner(payload)
          .then((response) => {
            const { data } = response;
            console.log("response", response);

            this.showMessageSnackbar(
              data.message
                ? data.message
                : data.error
                ? data.error.message
                  ? data.error.message
                  : data.error
                : data
            );

            if (data.message) {
              setTimeout(() => {
                this.$router.push("/events/eventbannerlist");
              }, 3000);
            }
          })
          .catch((error) => {
            console.log("Catch on Banner-getBannerByID Error: ", error);
            this.showMessageSnackbar(
              "Oops! Something went wrong. Kindly contact your administrator"
            );
          });
      }
    },

    handleFileUpload(var_Arr, event, index) {
      this.isLoader = true;
      let file = event.target.files[0];
      console.log(var_Arr, "sdajsdkn");

      let reader = new FileReader();
      let width = 0;
      let height = 0;

      reader.readAsDataURL(file);

      reader.onload = (evt) => {
        let img = new Image();
        img.onload = () => {
          width = img.width;
          height = img.height;
          if (
            (height == 488 &&
              width == 325 &&
              var_Arr == "event_info.e_image") ||
            (height >= 575 && width >= 1920 && var_Arr == "e_banners") ||
            (height >= 100 && width >= 100 && var_Arr == "od_logo")
          ) {
            // this.showMessageSnackbar('Valid')
            let files_name;
            if (event.target.files[0].size >= 2000000) {
              return this.showMessageSnackbar(
                "File size should be less than 2MB"
              );
            } else {
              files_name = event.target.files[0];
            }

            let formData = new FormData();

            if (var_Arr == "e_videos") {
              formData.append("assets/videos/media", files_name);
            } else {
              formData.append("assets/images/movies/banners/", files_name);
            }

            UploadService.uploadImages(formData)
              .then((response) => {
                console.log("Response!!=>", response.data);
                if (response.data.Status == true) {
                  this.showMessageSnackbar("File Uploaded Succesfully");
                } else {
                  this.showMessageSnackbar(response.data.Message);
                }
                console.log("SUCCESS!!=>", response.data.Path);

                this.event_banner_path = response.data.Path;
                this.isLoader = false;
              })
              .catch(function (err) {
                this.isLoader = false;
                console.log("Error in image upload ", err);
              });
          } else {
            this.isLoader = false;
            this.showMessageSnackbar("Enter Valid Image");
          }
        };
        img.src = evt.target.result;
      };
    },

    handleFileUploadTest(event) {
      this.staticBanner.eh_banner = event.target.files[0];
      let formData = new FormData();

      formData.append(
        "assets/images/movies/banners",
        this.staticBanner.eh_banner
      );

      for (var [key, value] of formData.entries()) {
        console.log(key, value);
      }

      UploadService.uploadImages(formData)
        .then((response) => {
          if (response.data.Status == true) {
            this.staticBanner.eh_banner = response.data.Path;
            alert("File Uploaded Succesfully");
          } else {
            alert(response.data.Message);
          }
          console.log("SUCCESS!!", response.data.Path);
        })
        .catch(function () {
          console.log("FAILURE!!");
        });
    },

    // getEventList: function() {
    //     MasterService.getEventList()
    //     .then((response) => {
    //         const { data } = response;
    //       if (data.data.length > 0) {
    //         this.eventList = response.data.data;
    //       } else {
    //         this.showMessageSnackbar(data.Message);
    //       }
    //     })
    //     .catch((error)=> {
    //         console.log("Catch on Events Category Error ", error);
    //         this.showMessageSnackbar(
    //         "Oops! Something went wrong. kindly contact your administrator"
    //       );
    //     });
    // },

    resetEventBanner: function () {
      (this.country_id = null),
        (this.event_name = ""),
        (this.staticBanner.status = false);
    },

    // Show Error Message
    showMessageSnackbar: function (message) {
      let x = document.getElementById("snackbar");
      x.className = "show";
      x.innerHTML = message;
      setTimeout(function () {
        x.className = x.className.replace("show", "");
      }, 3000);
    },
  },
};
</script>

<style></style>
